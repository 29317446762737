.c-sidebar { 
    background: hsla(208, 33%, 21%, 1);
  
    background: linear-gradient(
      90deg,
      hsla(208, 33%, 21%, 1) 0%,
      hsla(211, 36%, 46%, 1) 100%
    );
  
    background: -moz-linear-gradient(
      90deg,
      hsla(208, 33%, 21%, 1) 0%,
      hsla(211, 36%, 46%, 1) 100%
    );
  
    background: -webkit-linear-gradient(
      90deg,
      hsla(208, 33%, 21%, 1) 0%,
      hsla(211, 36%, 46%, 1) 100%
    );
  
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#243748", endColorstr="#4B749F", GradientType=1 );
  }
.loginbg{
    background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
}


 .c-body{
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
 }
         
 .c-footer {
  color: #fff;
  background: #1e2e3f;
  border-top: 1px solid #d8dbe0;
}
 
         
.btn-job {
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376  51%, #2b5876  100%);
  margin: 10px!important;
  padding: 10px 40px!important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white!important;            
  box-shadow: 0 0 20px #eee!important;
  border-radius: 10px!important;
  display: block;
}

.btn-job:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.c-sidebar .c-sidebar-brand {
  background: #ffffff!important;
}